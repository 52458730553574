import React from "react"
import Seo from "../components/SEO"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { PrismicRichText } from "@prismicio/react"
import { AdSlot } from "react-dfp"

import BannerModule from "../components/BannerModule/BannerModule"
// import Features from "../components/Features/Features"
import RecipeSummary from "../components/Recipe/RecipeSummary"

const RecipeTemplateStyles = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    .column {
      flex: 0 0 100%;

      @media (min-width: 768px) {
        flex-basis: 50%;

        &:nth-child(1) {
          padding-right: 20px;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      > * {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`

const RecipeTemplate = ({ data }) => {
  const title = data.title.text
  const meta_description = data.meta_description.text
  const name = data.name.text
  const thumbnail = data.thumbnail
  const recipe_yield = data.yield
  const prep_time = data.prep_time
  const cook_time = data.cook_time
  // const total_time = data.total_time
  const RecipeHeaderImage = getImage(thumbnail)
  const description = data.description.richText
  const ingredients = data.ingredients
  const steps = data.steps

  return (
    <>
      <Seo title={title} description={meta_description} />

      <BannerModule title={name}>
        <GatsbyImage
          className="banner__image"
          image={RecipeHeaderImage}
          alt={name}
        />
      </BannerModule>

      <RecipeTemplateStyles className="section">
        <div className="container container__tight">
          <RecipeSummary
            ingCount={ingredients.length}
            prepTime={prep_time}
            cookingTime={cook_time}
            recipeYield={recipe_yield.text}
          />
        </div>

        <div className="container container__tight">
          <AdSlot
            adUnit="kitchen_standart"
            sizes={[
              [970, 90],
              [728, 90],
              [468, 60],
              [300, 250],
              [300, 100],
              [320, 50],
              "fluid",
            ]}
            sizeMapping={[
              {
                viewport: [0, 0],
                sizes: [[320, 50], [300, 250], [300, 100], "fluid"],
              },
              { viewport: [468, 0], sizes: [[468, 60], "fluid"] },
              { viewport: [760, 0], sizes: [[728, 90], "fluid"] },
              { viewport: [1002, 0], sizes: [[970, 90], "fluid"] },
            ]}
          />
        </div>

        {description && (
          <div className="container container__tight">
            <div className="column">
              <PrismicRichText field={description} />
            </div>
          </div>
        )}

        {ingredients && (
          <div className="container container__tight">
            <div className="column">
              <h2>Інгредієнти</h2>
              <ul>
                {ingredients.map((ingredient, i) => (
                  <li key={i}>
                    {ingredient.recipe_ingredient.document?.data.name.text}:
                    {ingredient.quantity &&
                      ` ${ingredient.quantity} ${ingredient.dimension}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="container container__tight">
          <AdSlot
            adUnit="kitchen_standart"
            sizes={[
              [970, 90],
              [728, 90],
              [468, 60],
              [300, 250],
              [300, 100],
              [320, 50],
              "fluid",
            ]}
            sizeMapping={[
              {
                viewport: [0, 0],
                sizes: [[320, 50], [300, 250], [300, 100], "fluid"],
              },
              { viewport: [468, 0], sizes: [[468, 60], "fluid"] },
              { viewport: [760, 0], sizes: [[728, 90], "fluid"] },
              { viewport: [1002, 0], sizes: [[970, 90], "fluid"] },
            ]}
          />
        </div>

        {steps && (
          <div className="container container__tight">
            <div className="column">
              {steps.map((step, i) => (
                <div key={i}>
                  <h3>
                    Крок {i + 1}. {step.recipe_step_name.text}
                  </h3>
                  {step.recipe_step_image && (
                    <GatsbyImage
                      className="recipe__image"
                      image={getImage(step.recipe_step_image)}
                      alt={step.recipe_step_name.text}
                    />
                  )}
                  <PrismicRichText field={step.recipe_step_text.richText} />
                </div>
              ))}
            </div>
          </div>
        )}
      </RecipeTemplateStyles>

      <div className="section" css="margin: 30px 0">
        <div className="container container__tight">
          <AdSlot
            adUnit="kitchen_standart"
            sizes={[
              [970, 90],
              [728, 90],
              [468, 60],
              [300, 250],
              [300, 100],
              [320, 50],
              "fluid",
            ]}
            sizeMapping={[
              {
                viewport: [0, 0],
                sizes: [[320, 50], [300, 250], [300, 100], "fluid"],
              },
              { viewport: [468, 0], sizes: [[468, 60], "fluid"] },
              { viewport: [760, 0], sizes: [[728, 90], "fluid"] },
              { viewport: [1002, 0], sizes: [[970, 90], "fluid"] },
            ]}
          />
        </div>
      </div>

      {/* {gallery && (
        <RecipeGallery className="section">
          <div className="container container__tight">
            {gallery.map((item, index) => {
              let galleyImage = getImage(item)
              return <GatsbyImage key={index} image={galleyImage} />
            })}
          </div>
        </RecipeGallery>
      )} */}

      {/* <Features
        title="Featured Recipes from Barcadia."
        introduction="Vivamus quam mauris, pulvinar vel mauris id, interdum semper neque. Proin malesuada libero eget tellus scelerisque, id egestas tortor egestas."
      /> */}
    </>
  )
}

export default RecipeTemplate
