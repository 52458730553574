import React from "react"
import { graphql } from "gatsby"
import RecipeTemplate from "../../templates/recipe-template"
// import Seo from "../../components/SEO"
import Layout from "../../components/Layout"

const Product = ({ data: { prismicRecipe } }) => {
  return (
    <>
      <Layout>
        <RecipeTemplate data={prismicRecipe.data} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query recipeQuery($id: String) {
    prismicRecipe(id: { eq: $id }) {
      data {
        title {
          text
        }
        meta_description {
          text
        }
        name {
          text
        }
        thumbnail {
          gatsbyImageData(width: 2560, placeholder: BLURRED)
        }
        yield {
          text
        }
        prep_time
        cook_time
        total_time
        description {
          richText
        }
        ingredients {
          recipe_ingredient {
            document {
              ... on PrismicIngredient {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
          quantity
          dimension
        }
        steps {
          recipe_step_name {
            text
          }
          recipe_step_text {
            richText
          }
          recipe_step_image {
            gatsbyImageData(width: 1400, placeholder: BLURRED)
          }
        }
        notes {
          richText
        }
      }
    }
  }
`

export default Product
