import * as React from "react"
import styled from "styled-components"

const StyledRecipeSummary = styled.div`
  display: flex;
  gap: 1rem;
`

const RecipeSummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em 1em;
`

const RecipeSummaryItemLabel = styled.span`
  font-size: 0.875em;
`

const RecipeSummaryItemValue = styled.span`
  display: box;
  margin-top: 0.25em;
  color: var(--primary);
  font-size: 3em;
  font-weight: bold;
  font-family: "Yanone Kaffeesatz", sans-serif;
  text-shadow: var(--textShadow);
`

const RecipeSummary = ({ingCount, prepTime, cookingTime, recipeYield}) => (
  <StyledRecipeSummary>
    {ingCount && (
      <RecipeSummaryItem>
        <RecipeSummaryItemValue>{ingCount}</RecipeSummaryItemValue>
        <RecipeSummaryItemLabel>Інгредієнтів</RecipeSummaryItemLabel>
      </RecipeSummaryItem>
    )}
    {prepTime && (
      <RecipeSummaryItem>
        <RecipeSummaryItemValue>{prepTime} хв</RecipeSummaryItemValue>
        <RecipeSummaryItemLabel>Підготовка</RecipeSummaryItemLabel>
      </RecipeSummaryItem>
    )}
    {cookingTime && (
      <RecipeSummaryItem>
        <RecipeSummaryItemValue>{cookingTime} хв</RecipeSummaryItemValue>
        <RecipeSummaryItemLabel>Приготування</RecipeSummaryItemLabel>
      </RecipeSummaryItem>
    )}
    {recipeYield && (
      <RecipeSummaryItem>
        <RecipeSummaryItemValue>{recipeYield}</RecipeSummaryItemValue>
        <RecipeSummaryItemLabel>Вихід</RecipeSummaryItemLabel>
      </RecipeSummaryItem>
    )}
  </StyledRecipeSummary>
)

export default RecipeSummary
